@font-face {
  font-family: 'Spoof-Medium';
  src: local('Spoof-Medium'),
    url('https://d222i9ppxs2fqe.cloudfront.net/Spoof-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'Dharma-Gothic-E';
  src: url('./fonts/dharmaGotheic.woff');
}

@font-face {
  font-family: 'Spoof-Regular';
  src: local('Spoof-Regular'),
    url('https://d222i9ppxs2fqe.cloudfront.net/Spoof-Regular.woff2')
      format('woff2');
}

@font-face {
  font-family: 'Spoof-Bold';
  src: local('Spoof-Bold'),
    url('https://d222i9ppxs2fqe.cloudfront.net/Spoof-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gela';
  src: local('Gela'),
    url('https://d222i9ppxs2fqe.cloudfront.net/Gela.woff') format('woff');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Spoof-Medium', 'Spoof-Bold', 'Gela', 'Dharma-Gothic-E',
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* for adjusting scaling*/
/* @media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (-webkit-max-device-pixel-ratio: 2) {
  * {
    zoom: 0.98;
  }
} */

/* #tidio-chat-iframe { bottom: 3.5em !important; }  */
@media only screen and (max-width: 900px) {
  /*Edit the 0px number according to your website for mobile*/
  #tidio-chat-iframe {
    bottom: 60px !important;
  }
}

@media (max-width: 800px) {
  #chat-button {
    /* right: 0px !important; */
    bottom: 70px !important;
  }
}

#chat-window {
  /* right: 0px !important; */
  /* bottom: 130px !important; */
}

#chat-campaigns {
  /* right: 0px !important; */
  /* bottom: 70px !important; */
}
